










import { Component, Vue, Watch } from 'vue-property-decorator'
import Curriculum from '../StudentDetail/StudentDetail/Curriculum.vue'

@Component({
  components: {
    Curriculum,
  },
})
export default class StudentCurriculum extends Vue {}
