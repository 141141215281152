










































































































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
import { CurriculumController } from '@/services/request.service'
import { getToken, getSchoolInfo } from '@/utils/utils'
import moment, { Moment } from 'moment'
import flattenDeep from 'lodash/flattenDeep'
import { saveAs } from 'file-saver'

@Component({
  components: {},
})
export default class MyCurriculum extends Vue {
  @Prop({ default: () => {} }) public readonly curriculumData!: any
  @Prop() public readonly id!: any

  @Prop() public readonly yearKey!: number
  @Prop() public readonly startTime!: any
  @Prop() public readonly endTime!: any

  // private startTime = moment().startOf('isoWeek')
  // private endTime = moment().endOf('isoWeek')
  private exportLoading: boolean = false
  private substitute = 0
  private materialInfo: any = {
    title: '',
    infoList: [],
  }
  private detailModalShow: boolean = false

  private moment = moment

  private get studentInfo(): any {
    return this.$store.state.currentStudent
  }

  private get studentId(): any {
    return this.$store.state.currentStudent.studentId
  }

  private get week(): Array<any> {
    const classArranges = this.classArranges
    const classDay = this.curriculumData.dayOfArranged || []
    return [
      {
        name: '周一',
        enName: 'Mon',
        dayOfWeek: 1,
        dayOfArranged: classDay[0],
      },
      {
        name: '周二',
        enName: 'Tue',
        dayOfWeek: 2,
        dayOfArranged: classDay[1],
      },
      {
        name: '周三',
        enName: 'Wed',
        dayOfWeek: 3,
        dayOfArranged: classDay[2],
      },
      {
        name: '周四',
        enName: 'Thu',
        dayOfWeek: 4,
        dayOfArranged: classDay[3],
      },
      {
        name: '周五',
        enName: 'Fri',
        dayOfWeek: 5,
        dayOfArranged: classDay[4],
      },
      {
        name: '周六',
        enName: 'Sat',
        dayOfWeek: 6,
        dayOfArranged: classDay[5],
      },
      {
        name: '周日',
        enName: 'Sun',
        dayOfWeek: 7,
        dayOfArranged: classDay[6],
      },
    ]
      .map((item, index) => {
        if (index < 5) {
          if (classArranges && classArranges[item.dayOfWeek]) {
            return {
              ...item,
              ...classArranges[item.dayOfWeek],
            }
          } else {
            return item
          }
        } else {
          if (classArranges && classArranges[item.dayOfWeek]) {
            let showDay = false
            Object.keys(classArranges[item.dayOfWeek]).forEach(key => {
              if (classArranges[item.dayOfWeek][key]?.type !== 'periodArranges') {
                showDay = true
              }
            })
            return showDay
              ? {
                  ...item,
                  ...classArranges[item.dayOfWeek],
                }
              : undefined
          } else {
            return undefined
          }
        }
      })
      .filter(item => item)
  }

  private get classArranges(): Array<any> {
    return this.curriculumData.classArranges || []
  }

  private get total(): any {
    return this.curriculumData.total
  }

  private get periods(): Array<any> {
    return this.curriculumData.classPeriods || []
  }
  private get yearList(): Array<any> {
    return this.curriculumData.yearList || []
  }
  @Emit('changeTime')
  private prevWeek(): Array<any> {
    const startTime = moment(this.startTime.subtract(1, 'week'))
    const endTime = moment(this.endTime.subtract(1, 'week'))
    return [this.startTime, this.endTime]
  }

  @Emit('changeTime')
  private nextWeek(): Array<any> {
    const startTime = moment(this.startTime.add(1, 'week'))
    const endTime = moment(this.endTime.add(1, 'week'))
    return [this.startTime, this.endTime]
  }
  @Emit('changeYear')
  private changeYearFn(val): number {
    return Number(val)
  }
  private calcLeft(start): string {
    const startHour = moment(start).hours()
    const startMinute = moment(start).minutes()
    return (startHour - 8) * 150 + (startMinute / 60) * 150 + 'px'
  }

  private calcWidth(start, end): string {
    const startHour = moment(start).hours()
    const startMinute = moment(start).minutes()
    const endHour = moment(end).hours()
    const endMinute = moment(end).minutes()
    return (endHour - startHour) * 150 + ((endMinute - startMinute) / 60) * 150 + 'px'
  }

  private calcBg(course): string {
    let newColor = '#fff'
    if (!course) return newColor
    let color = (course.courseId.subject || {}).color
    if (color) {
      const r = parseInt(color.slice(1, 3), 16)
      const g = parseInt(color.slice(3, 5), 16)
      const b = parseInt(color.slice(5, 7), 16)
      newColor = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + '0.48)'
    }
    if (course.type === '1002') {
      return 'rgba(70,194,154,0.2)'
    } else if (course.type === 'periodArranges') {
      return 'rgba(128,128,154,0.04)'
    } else {
      return newColor
    }
  }

  private calcClassData(day, period): boolean {
    const { classArranges } = this
    const { dayOfWeek } = day
    const { classPeriodId } = period
    if (day[period.classPeriodId]) {
      return true
    }
    return false
  }

  private getTeachersName(course): any {
    return course ? course.teachers.map(item => item.name).join('、') : ''
  }

  private exportCurriculum(): void {
    if (this.exportLoading) return
    this.exportLoading = true
    setTimeout(() => {
      this.exportLoading = false
    }, 4000)
    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    let token = getToken()
    let schoolInfo = getSchoolInfo()
    let targetUrl = `${domain}exportCurriculum/${token}/${
      schoolInfo.schoolId
    }/${this.startTime.valueOf()}-${this.endTime.valueOf()}/${this.studentId}/${this.yearKey}`
    let url = `${printUrl}api/render/?url=${targetUrl}&pdf.landscape=true&pdf.printBackground=true&pdf.scale=0.52&pdf.margin.left=3.5mm&pdf.margin.right=4mm&pdf.margin.top=4mm&waitFor=1000`

    setTimeout(() => {
      const fileName = Object.keys(this.studentInfo || {}).length
        ? `${this.studentInfo.fullName}.pdf`
        : '课表.pdf'
      saveAs(url, fileName)
      // window.open(targetUrl)
    }, 0)
  }
}
